.login {
	text-align: center;
	input {
		height: 30px;
		font-size: 15px;
	}
	button {
		display: block;
		margin: 10px auto;
		padding: 10px 20px;
		font-size: 18px;
		font-weight: bold;
		border-radius: 6px;
		cursor: pointer;
		background: var(--main-color);
		color: white;
	}
}
