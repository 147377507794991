.inputRow {
	margin: 20px 0;
	label {
		display: block;
	}
	input,
	textarea,
	select {
		font-size: 15px;
	}
}
.fieldsetRow {
	display: flex;
	height: 30px;
	:nth-child(2) {
		margin-right: 10px;
	}
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
	legend {
		padding: 0;
	}
}

textarea {
	font-family: var(--font-family);
	width: 100%;
	height: 100px;
}

.button {
	display: block;
	margin: 10px auto;
	padding: 10px 20px;
	font-size: 18px;
	font-weight: bold;
	border-radius: 6px;
	cursor: pointer;
}

.submit {
	width: 200px;
	background: var(--main-color);
	color: white;
}

.cancel {
	width: 150px;
	background: var(--cancel-bg-color);
	color: var(--cancel-font-color);
}
