:root {
	--font-family: "Roboto", sans-serif;
	--main-color: rgba(61, 22, 211, 1);
	--cancel-bg-color: #b1b1b1;
	--cancel-font-color: #434343;
}
* {
	box-sizing: border-box;
}
body {
	background-color: #f1f1f1;
}
p {
	margin: 0;
}
button {
	border: 0;
}
.App {
	font-family: var(--font-family);
}
.srOnly {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

header {
	background: rgb(40, 211, 255);
	background: linear-gradient(
		124deg,
		rgba(40, 211, 255, 1) 20%,
		rgba(61, 22, 211, 1) 81%
	);
	color: white;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}
